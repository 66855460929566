import { MdEmail as IconEmail, MdLocationOn as IconLocation, MdPhone as IconPhone } from 'react-icons/md';
import { linking } from '../content/content';


export const HeroMain = ({name, role, email, phone, urlPhoto}) => {
    return (
        // <div className={`bg-gradient-to-t from-pink-600 to-pink-700 h-80 w-full flex flex-col justify-between pt-4 bg-[url('/src/assets/img/BG_Kartu_digital.png')]`}>
        <div className={`h-80 w-full bg-gradient-to-b from-pink-800 to-pink-600 flex flex-col justify-between pt-4`}>
            <div className={`flex flex-col items-center justify-center gap-3 h-[80%] bg-[url('/src/assets/img/BG_Kartu_digital.png')] bg-cover`}>
                <img className="p-1 w-28 h-28 overflow-auto object-cover rounded-full ring-2 ring-white bg-white" src={urlPhoto || `https://robohash.org/${name}`} alt="Bordered avatar" />
                <h2 className='text-white text-2xl font-medium'>{name}</h2>
                <h3 className='text-white text-sm font-thin'>{role}</h3>
                {/* <img alt='mutif logo' src={require('../../assets/img/Logo_BG.png')} className="w-1/2 self-center z-10" /> */}
            </div>
            <div className='grid grid-cols-3 divide-x border-t h-[20%] border-white border-opacity-40 divide-white divide-opacity-40'>
                <MenuHero onPress={(e) => linking(e, 'tel', phone)} childIcon={<IconPhone className={'text-xl'} />} label={'CALL'} />
                <MenuHero onPress={(e) => linking(e, 'mailto', email)} childIcon={<IconEmail className={'text-xl'} />} label={'EMAIL'} />
                <MenuHero onPress={(e) => linking(e, 'newTab', 'https://goo.gl/maps/GuJ8M9K8534LUfkGA')} childIcon={<IconLocation className={'text-xl'} />} label={'LOCATION'} />
            </div>
        </div>
    )
}

const MenuHero = ({childIcon, label, onPress}) => {
    return (
        <button onClick={onPress} className='text-white flex flex-col gap-1 items-center justify-center py-3'>
            {childIcon}
            <p className='text-xs'>{label}</p>
        </button>
    )
}