import { FaYoutube, FaInstagram, FaMapPin as IconPinMap, FaPhoneAlt as IconPhone } from 'react-icons/fa';
import {RiCopyrightLine as IconCopyRight} from 'react-icons/ri'
import { IcDomain, MutifCorpWhite } from '../../assets/img';
import { linking } from '../content/content';

export const FooterMain = () => {
    return (
        <div>
            <div className='bg-slate-800 p-5 px-15 md:px-[10%] box-content'>
                <div className={`flex w-full justify-between items-center`}>
                    <div className='w-1/4 md:w-[10%]'>
                        <img src={MutifCorpWhite} alt={'logo Mutif Career'} className={'w-full'} />
                    </div>
                    <div>
                        <p className={`text-white text-sm`}>IKUTI KAMI</p>
                        <div className={`flex gap-3 mt-2 text-2xl text-white`}>
                            {/* <div onClick={(e) => linking(e, 'newTab', 'https://www.facebook.com/mutifcorp')}> */}
                            <div className='cursor-pointer' onClick={(e) => linking(e, 'newTab', 'https://mutif.id/')}>
                                <img src={IcDomain} className='h-6' alt='Icon Domain' />
                            </div>
                            <div className='cursor-pointer' onClick={(e) => linking(e, 'newTab', 'https://www.instagram.com/mutifcorp/')}>
                                <FaInstagram />
                            </div>
                            <div className='cursor-pointer' onClick={e => linking(e, 'newTab', 'https://www.youtube.com/channel/UCcNyltfFTlg64puWkeaB14Q')}>
                                <FaYoutube />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-white mt-5 flex flex-col gap-2'>
                    <div className={`flex box-content gap-2 text-sm`}>
                        <IconPinMap />
                        <p>Jl. Raya Padalarang No.783, Padalarang, Kec. Padalarang, Kabupaten Bandung Barat, Jawa Barat 40553</p>
                    </div>
                    <div className={`flex box-content gap-2 text-sm items-center, cursor-pointer`} onClick={(e) => linking(e, 'tel', '(022) 8680060')}>
                        <IconPhone />
                        <p>(022) 8680060</p>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center bg-neutral-900 text-white py-1 text-md'>
                <IconCopyRight />
                <span className='mx-1'>2023</span>
                <p className='font-semibold uppercase'>copyright IT mutif corp</p>
            </div>
        </div>
    )
}