import { ip, own_ip } from '../../constant/url';

export const getDataUser = (params) => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ip}/api/officer/${params}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        res(result);
                    } else {
                        rej(result)
                    }
                })
                .catch(error => {
                    rej(error);
                });
        } catch (e) {
            rej(e);
        }
    })
}

export const getOwnDataUser = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${own_ip}/api/dumuser`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        res(result);
                    } else {
                        rej(result)
                    }
                })
                .catch(error => {
                    rej(error);
                });
        } catch (e) {
            rej(e);
        }
    })
}