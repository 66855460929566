import {ReactComponent as PageNotFoundIMG} from '../../assets/img/404NotPageFound.svg';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    return(
        <div className={'w-screen h-screen flex flex-col justify-center items-center px-10'}>
            <PageNotFoundIMG className='w-full md:w-1/3' />
            <button className={`mt-10 font-semibold text-xl bg-pink-500 px-5 py-2 text-white shadow rounded-md active:opacity-50`} onClick={() => navigate(-1)}>
                kembali
            </button>
        </div>
    )
}

export default PageNotFound;