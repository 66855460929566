import { GoogleMap, useLoadScript } from '@react-google-maps/api';

export default function MapScreen() {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: 'AIzaSyCD4ryiixOOYaU_XC3mK4R0IkPPmIDSQg8',
  })

  if(!isLoaded) return  <div>Loading Map....</div>
  return <Map />

  function Map() {
    return <GoogleMap zoom={10} center={{lat: -6.836825217069877, lng: 107.47233146884322}} mapContainerClassName={'w-full, h-screen'} />
  }
}