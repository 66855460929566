import IconFacebook from '../../assets/img/facebook.png';
import IconInstagram from '../../assets/img/instagram.png';
import { MdEmail as IconEmail, MdPhone as IconPhone, MdSave as IconSaveContact } from 'react-icons/md';
import { HiOfficeBuilding as IconCorp } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { IconWhatsapp } from '../../assets/img';

export function linking(e, type, data) {
    e.preventDefault();
    switch (type) {
        case 'mailto':
            console.log(`mailto:${data}`);
            window.location.href = `mailto:${data}`;
            break;
        case 'tel':
            console.log(`tel:${data}`);
            window.location.href = `tel:${data}`;
            break;
        case 'whatsapp':
            let number = "";
            if(data[0] === 0){
                data.slice(0, (data.length));
                number = `62${data}`;
            } else {
                number = data;
            }
            window.location.href = `https://wa.me/${number}`;
            break;
        case  'instagram':
            window.location.href = `https://www.instagram.com/${data}`
            break;
        case 'newTab':
            console.log(`New Tab : `, data);
            window.open(data.toString(), '_blank')
            break;
        default:
            console.log(`Error, tidak bisa linking`, { type, data })
            break;
    }
    
}

export const MainContent = ({ nohp, email, socmed, pressSave }) => {
    const styleIcon = 'text-4xl fill-gray-500 opacity-60';

    return (
        <div className='w-full'>
            <div className={`my-6`}>
                {/* <MenuContent childIcon={<IconPhone className={styleIcon} />} content={nohp} desc={'Nomor Telepon'} onPress={(e) => linking(e, 'tel', nohp)} /> */}
                {/* <MenuContent childIcon={<IconEmail className={styleIcon} />} content={email} desc={'Email'} onPress={(e) => linking(e, 'mailto', email)} /> */}
                {/* <MenuContent childIcon={<IconLocation className={styleIcon} />} content={address} desc={'Alamat Kantor'} /> */}
                <MenuContent childIcon={<IconPhone className={styleIcon} />} content={nohp} desc={'Nomor Telepon'} />
                <MenuContent childIcon={<IconEmail className={styleIcon} />} content={email} desc={'Email'} />
                <MenuContent childIcon={<IconCorp className={styleIcon} />} content={'Mutif Corp'} desc={'Kantor'} />
            </div>
            <div className='pl-16 pr-5 w-full grid grid-flow-row'>
                {(Array.isArray(socmed) && socmed.length > 0 ) && (
                    <div>
                        <h3 className='text-gray-500 mb-3'>Social Media</h3>
                        <div className={'flex gap-3'}>
                            {socmed.map((value, key) => {
                                const jenisSocmed = value?.jenis_sosmed;

                                return (

                                    <img onClick={(e) => linking(e, jenisSocmed === 101 ? 'whatsapp' : 'instagram', value.username)} key={key} src={jenisSocmed === 105 ? IconInstagram : jenisSocmed === 101 ? IconWhatsapp : IconFacebook} className={'w-10'} alt={'IconFacebook'} />
                                )
                            })}

                        </div>
                    </div>
                )}
                <div className='flex justify-end my-5'>
                    <button onClick={pressSave} className='flex items-center justify-center px-5 w-auto md:w-1/2 bg-gradient-to-r from-pink-600 hover:from-pink-700 hover:to-pink-600  to-pink-700 py-1 rounded-md shadow-md active:opacity-60 active:shadow-sm'>
                        <IconSaveContact className={'fill-white text-2xl mr-2'} />
                        <p className={`text-white font-medium text-lg`}>Unduh Kontak</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

const MenuContent = ({ childIcon, content, desc, onPress }) => {
    return (
        <div className='px-5 flex items-center gap-5'>
            {childIcon}
            <Link to={'#'} onClick={onPress} className='border-b border-gray-600 border-opacity-20 w-full py-7'>
                <p className='font-medium'>{content}</p>
                <p className='text-gray-500 text-sm'>{desc}</p>
            </Link>
        </div>
    )
}