import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import { HeroMain } from '../../components/hero';
import { MainContent } from '../../components/content/content';
// import { data_user } from '../../constant/db';
import { MainLoader } from '../../components/loader';
import { getDataUser } from '../../tools/api/getDataUser';
import { own_ip, ip } from '../../constant/url';
import { FooterMain } from '../../components/footer/footer';
import { useNavigate, useParams } from 'react-router-dom';

const Home = () => {
    const [dataUser, setDataUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [nameUser, setNameUser] = useState("");
    const [noHp, setNoHp] = useState("");
    const [email, setEmail] = useState("");
    const [position, setPosition] = useState("");
    const [socmed, setSocmed] = useState(null);
    const [photoUrl, setPhotoUrl] = useState("");

    const { saveAs } = FileSaver;
    const params = useParams();
    const { uid, subpath } = params;
    const combinedUrl = uid + (subpath ? '/' + subpath : '');

    const navigate = useNavigate();

    useEffect(() => {
        getFirstData();
        // console.log(`params`, params);
        // eslint-disable-next-line
    }, []);

    function getFirstData() {
        getDataUser(combinedUrl)
            .then(response => {
                const _data = response.data;
                // console.log(`_data`, _data?.photo);
                if (_data) {
                    setDataUser(_data);
                    setNameUser(_data?.name);
                    setNoHp(_data?.personal?.no_whatsapp_1);
                    setEmail(_data?.personal?.email_pribadi);
                    setPosition(_data?.seksi);
                    setSocmed(_data?.socmed);
                    setPhotoUrl(_data?.photo);
                    // getPhoto(_data.photo);
                }
                setIsLoading(false);
            })
            .catch(err => {
                // console.log(`Error`, err);
                navigate('/not-found')
            })
    }

/*     function getPhoto(uriPhoto) {
        fetch(`${ip}/`)
            .then(() => {
                setPhotoUrl(uriPhoto);
            })
            .catch(error => {
                console.log(`Error get Image`, error);
                setPhotoUrl("");
            })
    } */

    function saveContact() {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${own_ip}/api/vcard?firstName=${dataUser?.name || ""}&middleName=${dataUser?.middleName || ""}&lastName=${dataUser?.lastName || ""}&cellPhone=${noHp || ""}&email=${email || ""}&organization=CV Mutif Corp`, requestOptions)
                .then(async res => {
                    const response = await res.json();
                    console.log(`Response`, response);
                    if (response.status === "success") {
                        const blob = new Blob([response.data], { type: "text/vcard;charset=utf-8" });
                        saveAs(blob, `${dataUser?.name}.vcf`);
                    }

                })
                .catch(err => {
                    console.log(`Error`, err);
                })
        } catch (error) {
            console.log("Error save contact", error)
        }
    }


    return (
        <div className='flex w-screen flex-col relative'>
            <div className={`${isLoading ? 'opacity-100' : 'opacity-0'} transition-opacity`}>
                <MainLoader />
            </div>
            <div className={`${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500 w-full flex flex-col items-center relative`}>
                <div className='hidden md:block h-80 bg-gradient-to-t from-pink-600 to-pink-700 w-full'>
                </div>
                <div className='w-full md:w-[50%] md:shadow-lg md:rounded md:py-5 md:absolute md:z-20 my-0 md:my-3'>
                    <HeroMain name={nameUser || '#_ERROR'} role={position || '#_ERROR'} email={email} phone={noHp} urlPhoto={photoUrl ? `${ip}/${photoUrl}` : `https://ui-avatars.com/api/?name=${nameUser}&size=128&color=be185d&background=fbcfe8&rounded=true`} />
                    <MainContent address={"Jl. Raya Padalarang No.783, Padalarang, Kec. Padalarang, Kabupaten Bandung Barat, Jawa Barat 40553" || '#_ERROR'} email={email || '#_ERROR'} socmed={socmed} nohp={noHp || '#_ERROR'} pressSave={() => {
                        saveContact();
                    }} />
                    <FooterMain />
                </div>
            </div>
        </div>
    )
}



export default Home;