import Home from "./pages/home/home";

import { Routes, Route } from 'react-router-dom';
import PageNotFound from "./helper/handling/404NotFound";
import { ScrollToTop } from "./helper/scrollToTop";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MapScreen from "./pages/lib/map";

function App() {
  return (
    <>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path={'*'} element={<PageNotFound />} />
        <Route path={'/not-found'} element={<PageNotFound />} />
        <Route path={'/:uid'} element={<Home />} />
        <Route path={'/:uid/:subpath'} element={<Home />} />
        <Route path={'/location'} element={<MapScreen />} />
      </Routes>
    </>
  );
}

export default App;
